import React, {useEffect, useState} from "react";
import LazyLoad from "react-lazyload";

import {useHistory, useParams} from "react-router-dom";
import ReztoSwitch from "../common/ReztoSwitch";
import {assertTokenValidity} from "../common/ReztoToken";
import FormDialog from "../Modal/FormDialog";
import {Flow, FlowCategory} from "../Model/Flow";
import FlowCategoryCard from "./FlowCategoryCard";
import FlowCategoryForm from "./Forms/FlowCategoryForm";
import CopyFlowForm from "./CopyFlowForm";
import ActionDropdown, {ACTION_DROPDOWN_ENUM} from "../common/ActionDropdown/ActionDropdown";
import ReorderForm from "../common/ReorderForm";
import ChangeNameForm from "../common/ChangeNameForm";
import DeleteForm from "../common/DeleteForm";
import {userReztoStore} from "../state/rootStore";

function FlowSection() {
    const {reztoAuth, flowStateSlice} = userReztoStore();
    const {flowId} = useParams<{ flowId: string }>();
    const flows = flowStateSlice.flows;
    const history = useHistory()
    const flow: Flow = flows.find((flow: Flow) => flow.id === flowId)!;
    const [actionDialog, setActionDialog] = useState('');
  
    const [flowCategories, setFlowCategories] = useState(flow?.flowCategories.map((flowCategory: FlowCategory) => ({
        id: flowCategory.id,
        content: flowCategory,
        title: flowCategory.name
    })));

    useEffect(() => {
        if (flow) {
            setFlowCategories(flow.flowCategories.map((flowCategory: FlowCategory) => ({
                id: flowCategory.id,
                content: flowCategory,
                title: flowCategory.name
            })))
        }
    }, [flow])


    if (!flow) {
        return (<></>)
    }


    const changeFlowName = (data: any) => {
        const newFlow = {...flow}
        newFlow.displayTitle = data.name;
        updateFlow(newFlow);
    }

    function updateFlowCategoryOrder() {
        const newFlow = {...flow, flowCategories: flowCategories.map(reorderItem => reorderItem.content)}
        updateFlow(newFlow);
    }

    function updateFlow(newFlow: Flow) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                flowStateSlice.editFlow(newFlow, newFlow.id, reztoAuth);
            });
    }

    function deleteFlow(confirmed: boolean) {
        if (confirmed) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    flowStateSlice.deleteFlow(flow.id, reztoAuth);
                    history.push("/restaurants/" + flow.restaurantId + "/flows");
                });
        }
    }

    function publish() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                flowStateSlice.publishFlow(flow.id,
                    reztoAuth, !flow.published);
            });
    }

    function onCopyFlow(ok: boolean) {
        if (ok) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    flowStateSlice.copyFlow(flow.id, reztoAuth);
                });
        }
    }


    function dismissDialog() {
        setActionDialog('')
    }

    return (
        <>
            <div className="flex justify-between">


                <div className="flex flex-col justify-center items-center ">
                    <h1 className="text-header p-3 mt-10">{flow.displayTitle}</h1>
                    {/*<div className="flex flex-row justify-start w-full p-3">*/}
                    {/*    <ReztoSwitch defaultChecked={flow.published} callback={publish}/>*/}
                    {/*</div>*/}
                </div>


                <div className="flex flex-row justify-end content-center items-center">

                    <ActionDropdown actionDropdownItems={[
                        {
                            icon: ACTION_DROPDOWN_ENUM.REORDER, label: "Ändra ordning", action: function () {
                                setActionDialog('reorder')
                            }
                        },
                        {
                            icon: ACTION_DROPDOWN_ENUM.ADD, label: "Nytt flöde", action: function () {
                                setActionDialog('create')
                            }
                        },
                        {
                            icon: ACTION_DROPDOWN_ENUM.EDIT, label: "Byt namn", action: function () {
                                setActionDialog('changeName')
                            }
                        },
                        {
                            icon: ACTION_DROPDOWN_ENUM.DELETE, label: "Radera", action: function () {
                                setActionDialog('delete')
                            }
                        },
                        {
                            icon: ACTION_DROPDOWN_ENUM.COPY, label: "Kopiera", action: function () {
                                setActionDialog('copy')
                            }
                        },
                        {
                            icon: flow.published ? ACTION_DROPDOWN_ENUM.UNPUBLISH : ACTION_DROPDOWN_ENUM.PUBLISH, label: flow.published ? "Avpublicera" : "Publicera", action: function () {
                                publish()
                            }
                        },
                    ]}/>

                    {actionDialog === 'reorder' &&
                        <ReorderForm title={"Ändra ordning"} onDismiss={dismissDialog}
                                     onClick={updateFlowCategoryOrder}
                                     reorderItems={flowCategories} setReorderItems={setFlowCategories}/>}
                    {actionDialog === 'create' &&
                        <FormDialog title={"Ny flödeskategori"}
                                    open={true} setOpen={dismissDialog}
                        >
                            <FlowCategoryForm
                                flowId={flowId}
                                setCreateDialogOpen={dismissDialog}/>
                        </FormDialog>}
                    {actionDialog === 'changeName' &&
                        <ChangeNameForm title={"Byt namn på flöde"}
                                        previousName={flow.displayTitle} onClick={changeFlowName}
                                        onDismiss={dismissDialog}/>}
                    {actionDialog === 'delete' &&
                        <DeleteForm confirmText={"Vill du verkligen ta bort flödet?"}
                                    onClick={deleteFlow} onDismiss={dismissDialog}/>}
                    {actionDialog === 'copy' &&
                        <CopyFlowForm onClick={onCopyFlow} onDismiss={dismissDialog}/>}
                </div>
            </div>

            <hr className="border-secondary border-t-2 border-solid"/>
            <div className="flex flex-col flex-wrap pt-5 gap-1">
                {
                    flow.flowCategories.map((flowCategory: FlowCategory) => {
                        return <LazyLoad key={flowCategory.id} height={200}><FlowCategoryCard
                            flowCategoryId={flowCategory.id}/></LazyLoad>
                    })
                }
            </div>

        </>

    );
}

export default FlowSection;

