import 'firebase/storage';
import React, {useEffect, useState} from 'react';

import './menu.css';
import {Menu, MenuCategory, MenuModel, MenuProduct} from "../Model/Menu";
import {Product} from "../Model/Product";
import MenuProductCard from "./MenuProductCard";
import FormDialog from "../Modal/FormDialog";
import MenuProductForm from "./forms/MenuProductForm";
import {assertTokenValidity} from "../common/ReztoToken";
import ReztoSwitch from '../common/ReztoSwitch';
import ActionDropdown, {ACTION_DROPDOWN_ENUM} from "../common/ActionDropdown/ActionDropdown";
import ReorderForm from "../common/ReorderForm";
import ChangeNameForm from "../common/ChangeNameForm";
import DeleteForm from "../common/DeleteForm";
import {userReztoStore} from "../state/rootStore";

function MenuCategoryCard({ menuCategory, menuModelId, menuId }: {
    menuCategory: MenuCategory,
    menuModelId: string, menuId: string
}) {


    const {reztoAuth, productStateSlice, menuModelStateSlice} = userReztoStore();
    const menuModels: MenuModel[] = menuModelStateSlice.menuModels;
    const menuModel: any = menuModels.find((menuModel: MenuModel) => menuModel.id === menuModelId)
  
    const [actionDialog, setActionDialog] = useState('');
    const [menuProducts, setMenuProducts] = useState(menuCategory.menuProducts.map((menuProduct: MenuProduct) => {
        let product = productStateSlice.products.find((product: Product) => product.id === menuProduct.productId);
        return (
            {
                id: menuProduct.id,
                content: menuProduct,
                title: product ? product.name : menuProduct.productId
            })
    }));

    useEffect(() => {
        setMenuProducts(menuCategory.menuProducts.map((menuProduct: MenuProduct) => {
            let product = productStateSlice.products.find((product: Product) => product.id === menuProduct.productId);
            return (
                {
                    id: menuProduct.id,
                    content: menuProduct,
                    title: product ? product.name : menuProduct.productId
                })
        }))

    }, [menuCategory.menuProducts, menuCategory.menuProducts.length, productStateSlice.products])


    function updateMenuProductOrder() {
        const newMenu: Menu = { ...menuModel.menus.find((m: Menu) => m.id === menuId) }
        let newMenuCategory: any = { ...newMenu.menuCategories.find(mc => mc.id === menuCategory.id) }

        // Updating menucategory
        newMenuCategory.menuProducts = menuProducts.map(mp => mp.content);
        updateMenuModel(newMenu, newMenuCategory);
    }


    const onMenuCategoryNameChange = (data: any) => {
        const newMenu: Menu = { ...menuModel.menus.find((m: Menu) => m.id === menuId) }
        let newMenuCategory: any = { ...newMenu.menuCategories.find(mc => mc.id === menuCategory.id) }

        // Updating menucategory
        newMenuCategory.name = data.name;
        updateMenuModel(newMenu, newMenuCategory);
    }


    function updateMenuModel(newMenu: Menu, newMenuCategory: any) {
        newMenu.menuCategories = newMenu.menuCategories.map(mc => {
            return mc.id === menuCategory.id ? newMenuCategory : mc
        })
        let newMenuModel = {
            ...menuModel,
            menus: menuModel.menus.map((menu: Menu) => menu.id === menuId ? newMenu : menu)
        }
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.editMenuModel(newMenuModel, reztoAuth);
            });
    }


    function deleteMenuCategory(confirmed: boolean) {
        if (confirmed) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    menuModelStateSlice.deleteMenuCategory(menuModelId, menuId, menuCategory.id, reztoAuth);
                });

        }
    }

    function publish() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.publishMenuCategory(menuModelId, menuId, menuCategory.id, reztoAuth, !menuCategory.published);
            });
    }

    function dismissDialog(){
        setActionDialog('')
    }

    if (!menuCategory) {
        return (<></>);
    }



    return (
        <div key={menuCategory.id} className="p-5">

            <div className="flex sm:flex-col lg:flex-row justify-between">


                <div className="flex flex-row w-full justify-start items-center ">
                    <h2 className="text-normal text-3xl pb-5">{menuCategory.name}</h2>
                </div>


                <div className="flex flex-row justify-end">
                    <ActionDropdown actionDropdownItems={[
                        {icon: ACTION_DROPDOWN_ENUM.REORDER, label: "Ändra ordning", action: function(){setActionDialog('order')}},
                        {icon: ACTION_DROPDOWN_ENUM.ADD, label: "Ny menyprodukt", action: function(){setActionDialog('create')}},
                        {icon: ACTION_DROPDOWN_ENUM.EDIT, label: "Byt namn", action: function(){setActionDialog('changeName')}},
                        {icon: ACTION_DROPDOWN_ENUM.DELETE, label: "Radera", action: function(){setActionDialog('delete')}},
                        {icon: menuCategory.published ? ACTION_DROPDOWN_ENUM.UNPUBLISH :  ACTION_DROPDOWN_ENUM.PUBLISH, label: menuCategory.published ? "Avpublicera" : "Publicera", action: function(){publish()}},
                    ]}/>

                </div>
                {actionDialog === 'order' &&
                    <ReorderForm title={"Ändra ordning på menyprodukter"} onDismiss={dismissDialog}
                                 onClick={updateMenuProductOrder}
                                 reorderItems={menuProducts} setReorderItems={setMenuProducts} />
                }
                {actionDialog === 'create' &&
                    <FormDialog title={"Ny menyprodukt"}
                        open={true} setOpen={dismissDialog}
                    >
                        <MenuProductForm setCreateDialogOpen={dismissDialog}
                            menuModelId={menuModelId}
                            menuId={menuId}
                            menuCategoryId={menuCategory.id}
                        />
                    </FormDialog>
                }
                {actionDialog === 'changeName' &&
                    <ChangeNameForm title={"Byt namn på menykategori"}
                                    previousName={menuCategory.name} onClick={onMenuCategoryNameChange} onDismiss={dismissDialog} />
                }
                {actionDialog === 'delete' &&
                    <DeleteForm confirmText={"Vill du verkligen ta bort menykategorin?"}
                                onClick={deleteMenuCategory} onDismiss={dismissDialog} />
                }


            </div>

            <div className="pr-5 pl-5">
                <hr />
            </div>
            <div className="flex flex-row flex-wrap">

                {
                    menuCategory.menuProducts.map((menuProduct, index) => {
                        let product = productStateSlice.products.find((product: Product) => product.id === menuProduct.productId);
                        if (product) {
                            return (
                                <MenuProductCard
                                    key={menuProduct.id}
                                    menuModelId={menuModelId}
                                    menuCategoryId={menuCategory.id}
                                    menuId={menuId}
                                    menuProduct={menuProduct} />
                            )
                        }
                        return null;
                    })
                }
            </div>


        </div>
    )
}

export default MenuCategoryCard;
