import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from "react-hook-form";

import {useHistory, useParams} from "react-router-dom";
import {
    ALLERGIES,
    PRODUCT_DISPLAY_TYPE,
    PRODUCT_PAYMENT_TYPE,
    TEMPLATE_TYPE,
    VAT_PRODUCT_TYPE,
    VAT_TYPE
} from '../common/Constants';
import RadioButtonGroup from '../common/RadioButtonGroup';
import CheckBoxNormal from './CheckboxNormal';
import InputField from '../common/InputField';
import {Product} from "../Model/Product";
import {Restaurant} from "../Model/RestaurantChainAggregate";
import {assertTokenValidity} from "../common/ReztoToken";
import ImagePicker from "./ImagePicker";
import ReztoImage from "../common/Image/ReztoImage";
import TagsCheckbox from "./TagsCheckbox";
import ReztoSwitch from "../common/ReztoSwitch";
import ConfirmDialog from "../Modal/ConfirmDialog";
import {userReztoStore} from "../state/rootStore";

function EditProduct() {
    let {productId} = useParams<{ productId: string }>();
    let {restaurantId} = useParams<{ restaurantId: string }>();

    const history = useHistory()
    const {reztoAuth, productStateSlice} = userReztoStore();
    const {restaurantChainAggregateState} = userReztoStore();
  
    const product = productStateSlice.products.filter((product: Product) => product.id === productId)[0];
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const [copyDialogOpen, setCopyDialogOpen] = useState(false);


    let previousProduct = product ?
        product : {
            'imageUrl': "https://rezto-menuitems.s3.eu-north-1.amazonaws.com/rezto_standard_green.png",
            'allergies': [],
            'categories': [],
            'tags': [],
            'selectableIngredients': [],
            'templateType': TEMPLATE_TYPE.Ingen,
            'productPaymentType': PRODUCT_PAYMENT_TYPE.Maträtt,
            'productDisplayType': PRODUCT_DISPLAY_TYPE.Maträtt,
            'vatPercentage': VAT_TYPE['25 %'],
            'vatProductGroup': VAT_PRODUCT_TYPE.Mat

        }
    let allCategories: any = productStateSlice.products.map((p: Product) => p.categories)
    allCategories = [].concat.apply([], allCategories);
    allCategories = [...Array.from(new Set(allCategories))]

    let allTags: any = productStateSlice.products.map((p: Product) => p.tags)
    allTags = [].concat.apply([], allTags);
    allTags = [...Array.from(new Set(allTags))]

    let allSelectableIngredients: any = productStateSlice.products.map((p: Product) => p.selectableIngredients)
    allSelectableIngredients = [].concat.apply([], allSelectableIngredients);
    allSelectableIngredients = [...Array.from(new Set(allSelectableIngredients))]

    let originalPrice = product ?
        product.prices.find((price: any) => price.priceCategory === "ORIGINAL")?.amount?.amount : null;
    let takeAway = product ?
        product.prices.find((price: any) => price.priceCategory === "TAKEAWAY")?.amount?.amount : null;

    const {register, reset, handleSubmit, control, getValues} = useForm({
        defaultValues: useMemo(() => {
            return {
                eatHere: originalPrice ? originalPrice / 100 : originalPrice,
                takeAway: takeAway ? takeAway / 100 : takeAway,
                costPriceForm: product?.costPrice ? product?.costPrice?.amount / 100 : 0,
                ...previousProduct
            };
        }, [product])
    });

    useEffect(() => {
        if (product) {
            // setImageAsUrl(prevObject => ({...prevObject, imgUrl: product.imageUrl}))
            setSelectedImageUrl(product.imageUrl)
        }
        reset({
            ...previousProduct,
            eatHere: originalPrice ? originalPrice / 100 : originalPrice,
            takeAway: takeAway ? takeAway / 100 : takeAway,
            costPriceForm: product?.costPrice ? product?.costPrice?.amount / 100 : 0
        });
    }, [product]);


    const onSub = (data: any) => {

        let prices = [];
        if (data.eatHere) {
            prices.push({
                'priceCategory': 'ORIGINAL',
                'amount': {
                    'amount': (data.eatHere * 100).toString()
                },
                "discount": null,
                "hasDiscount": null
            });
        }

        if (data.takeAway) {
            prices.push({
                'priceCategory': 'TAKEAWAY',
                'amount': {
                    'amount': (data.takeAway * 100).toString()
                },
                "discount": null,
                "hasDiscount": null
            });
        }

        data = {
            name: data.name,
            desc: data.desc,
            restaurantId: restaurantId,
            restaurantChainId: restaurantChainAggregateState.restaurants.find((restaurant: Restaurant) => restaurant.id === restaurantId)!.restaurantChainId,
            templateType: data.templateType,
            productPaymentType: data.productPaymentType,
            productDisplayType: data.productDisplayType,
            categories: data.categories === false ? [] :
                typeof data.categories === 'string' ? [data.categories] : data.categories,
            tags: data.tags === false ? [] :
                typeof data.tags === 'string' ? [data.tags] : data.tags,
            prices: prices,
            costPrice: {
                'amount': (+data.costPriceForm * 100).toString()
            },
            vatPercentage: data.vatPercentage,
            vatProductGroup: data.vatProductGroup,
            imageUrl: selectedImageUrl,
            allergies: data.allergies,
            ingredients: null,
            selectableIngredients: data.selectableIngredients === false ? [] :
                typeof data.selectableIngredients === 'string' ? [data.selectableIngredients] : data.selectableIngredients
        };


        if (productId) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    productStateSlice.updateProduct(data, reztoAuth, history, restaurantId, productId);
                });

        } else {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    productStateSlice.createProduct(data, reztoAuth, history, restaurantId);
                    // myDispatch(SAVE_PRODUCT_EFFECT(data, reztoAuth, history, restaurantId));
                });

        }
    };

    function publish(checked: boolean) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                productStateSlice.publishProduct(product.id, reztoAuth, !checked);
            });
    }



    function copy(){
        assertTokenValidity(reztoAuth)
            .then((_) => {
                productStateSlice.copyProduct({
                    productId: product.id,
                    newName: product.name + " - COPY"
                }, reztoAuth, history, restaurantId);
            });
    }


    if (productId && !product) {
        return (<>

        </>);
    }

    return (
        <>
            {
                <>
                    <div className="flex justify-between items-center">
                        <h1 className="text-4xl mt-5 mb-2 text-mainFont">{product ? 'Redigera produkt' : 'Ny produkt'}</h1>

                        {product &&
                            <>
                                <ReztoSwitch defaultChecked={product?.published} callback={publish}/>
                                <ConfirmDialog callBack={copy} title={"Kopiera"} open={copyDialogOpen} setOpen={setCopyDialogOpen}/>
                                <button
                                    onClick={() => { setCopyDialogOpen(true)}}
                                    className="dropdown-button h-10">Kopiera
                                </button>
                            </>

                        }



                    </div>
                    <hr/>

                    <form autoComplete="off" className="flex flex-col mt-5" onSubmit={handleSubmit(onSub)}>

                        <div className="flex flex-row justify-between lg:flex-row xs:flex-col">
                            <div className="flex-one mr-10">
                                <InputField name="name" title="Produktnamn" control={control}/>
                                <InputField name="eatHere" title="Äta här" control={control}/>
                                <InputField name="takeAway" title="Take away" control={control}/>
                                <InputField name="costPriceForm" title="Inköpespris" control={control}/>
                            </div>

                            <div className="flex-one">
                                {selectedImageUrl !== "" &&
                                    <ReztoImage imageUrl={selectedImageUrl} height={300} width={300}/>
                                }
                                <ImagePicker setSelectedImageUrl={setSelectedImageUrl}/>

                            </div>


                        </div>


                        <label className="input-label-normal">Produktens beskrivning</label>
                        <textarea rows={3} className="input-normal bg-secondary"
                                  placeholder={'Beskrivning'}  {...register("desc")} />

                        <TagsCheckbox
                            title={'Kategorier'}
                            register={register}
                            values={allCategories.filter((a: any) => a)}
                            formControlName={'categories'}
                        />

                        <TagsCheckbox
                            title={'Valbara ingredienser'}
                            register={register}
                            values={allSelectableIngredients.filter((a: any) => a)}
                            formControlName={'selectableIngredients'}
                        />

                        <TagsCheckbox
                            title={'Taggar'}
                            register={register}
                            values={allTags.filter((a: any) => a)}
                            formControlName={'tags'}
                        />

                        <CheckBoxNormal
                            title={'Allergier'}
                            register={register}
                            values={ALLERGIES}
                            formControlName={'allergies'}
                        />


                        <RadioButtonGroup title={'Template'} values={TEMPLATE_TYPE} register={register}
                                          formControlName={'templateType'}/>
                        <RadioButtonGroup title={'Maträtt'} values={PRODUCT_PAYMENT_TYPE} register={register}
                                          formControlName={'productPaymentType'}/>
                        <RadioButtonGroup title={'Presentationstyp'} values={PRODUCT_DISPLAY_TYPE} register={register}
                                          formControlName={'productDisplayType'}/>
                        <RadioButtonGroup title={'Moms'} values={VAT_TYPE} register={register}
                                          formControlName={'vatPercentage'}/>
                        <RadioButtonGroup title={'Momssortering'} values={VAT_PRODUCT_TYPE} register={register}
                                          formControlName={'vatProductGroup'}/>

                        <div className="flex justify-around">
                            <button type="submit" className="dropdown-button mb-12">Spara
                            </button>

                        </div>
                    </form>
                    {/*<button className="dropdown-button" type="button" onClick={() => {*/}
                    {/*    assertTokenValidity(reztoAuth)*/}
                    {/*        .then((_) => {*/}
                    {/*            productStateSlice.deleteProduct(productId, reztoAuth, history, restaurantId);*/}
                    {/*        });*/}
                    {/*}}>Radera*/}
                    {/*</button>*/}
                </>
            }
        </>
    )
}

export default EditProduct;
