import {ReztoAuth} from "../Model/AuthModel";
import {StoreSlice} from "./rootStore";
import axios from "axios";
import {MenuModel, MenuProduct} from "../Model/Menu";
import {GetHeaders} from "./AuthCommon";

interface MenuModelState {
    menuModels: MenuModel[],
    fetchMenuModels: (restaurantId: string, reztoAuth: ReztoAuth) => void;
    createMenuModel: (menuModel: any, reztoAuth: ReztoAuth) => void;
    editMenuModel: (menuModel: MenuModel, reztoAuth: ReztoAuth) => void;
    deleteMenuModel: (menuModelId: string, reztoAuth: ReztoAuth) => void;
    publishMenuModel: (menuId: string, reztoAuth: ReztoAuth, published: boolean) => void;
    createMenu: (menu: any, menuModelId: string, reztoAuth: ReztoAuth) => void;
    deleteMenu: (menuModelId: string, menuId: string, reztoAuth: ReztoAuth) => void;
    publishMenu: (menuModelId: string, menuId: string, reztoAuth: ReztoAuth, published: boolean) => void;
    copyMenu: (fromMenuModelId: string, toMenuModelId: string, menuId: string, reztoAuth: ReztoAuth) => void;
    createMenuCategory: (menuModelId: string, mainCategoryId: string, menuCategory: any, reztoAuth: ReztoAuth) => void;
    deleteMenuCategory: (menuModelId: string, mainCategoryId: string, menuCategoryId: string, reztoAuth: ReztoAuth) => void;
    publishMenuCategory: (menuModelId: string, mainCategoryId: string, menuCategoryId: string, reztoAuth: ReztoAuth, published: boolean) => void;
    createMenuProduct: (menuModelId: string, menuId: string, menuCategoryId: string, menuProduct: any, reztoAuth: ReztoAuth) => void;
    deleteMenuProduct: (menuModelId: string, menuId: string, menuCategoryId: string, menuProductId: string, reztoAuth: ReztoAuth) => void;
    publishMenuProduct: (menuModelId: string, menuId: string, menuCategoryId: string, menuProductId: string, reztoAuth: ReztoAuth, published: boolean) => void;

}

export interface MenuModelSlice {
    menuModelStateSlice: MenuModelState;
}

function deleteMenu(state: any, result: any){
    let menuModel: any = state.menuModels.find((menuModel: any) => menuModel.id === result.menuModelId);
    menuModel.menus = menuModel.menus.filter((menu: any) => menu.id !== result.menuId)
    let menuModels: any = state.menuModels.filter((menu: any) => menu.id !== result.menuModelId)
    menuModels.push(menuModel);
    return menuModels;
}


function deleteMenuCategory(state: MenuModelSlice,  menuModelId: string, menuId: string, menuCategoryId: string,){
    let indexOf = state!.menuModelStateSlice!.menuModels!
        .find(x => x.id === menuModelId)!.menus
        .find(x => x.id === menuId)!.menuCategories.map(x => x.id).indexOf(menuCategoryId);
    if(indexOf > -1){
        state!.menuModelStateSlice!.menuModels!
            .find(x => x.id === menuModelId)!.menus
            .find(x => x.id === menuId)!.menuCategories.splice(indexOf, 1);
    }
    return state;
}

function setPublishMenuProduct(state: MenuModelSlice, menuModelId: string, menuId: string, menuCategoryId: string, menuProductId: string, published: boolean) {
    state!.menuModelStateSlice!.menuModels!
        .find(x => x.id === menuModelId)!.menus
        .find(x => x.id === menuId)!.menuCategories
        .find(x => x.id === menuCategoryId)!.menuProducts
        .find((x: MenuProduct) => x.id === menuProductId)!.published = published;
    return state;
}

export const createMenuModelSlice: StoreSlice<MenuModelSlice> = (set, get) => ({
    menuModelStateSlice: {
        menuModels: [],
        fetchMenuModels: (restaurantId: string, reztoAuth: ReztoAuth) => {
            axios.get(process.env.REACT_APP_ENGINE_BASE_URL + '/menu-models/restaurant/' + restaurantId, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: result.data
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createMenuModel: (menuModel: any, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + '/menu-models', menuModel, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: [...prev.menuModelStateSlice.menuModels, result.data]
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        editMenuModel: (menuModel: MenuModel, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/menu-models/" + menuModel.id, menuModel,{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.map((menuModel: any) => menuModel.id === result.data.id ? result.data : menuModel)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        deleteMenuModel: (menuModelId: string, reztoAuth: ReztoAuth) => {
            axios.delete(process.env.REACT_APP_ENGINE_BASE_URL + "/menu-models/" + menuModelId,{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.filter((menuModel: any) => menuModel.id !== result.data)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        publishMenuModel: (menuId: string, reztoAuth: ReztoAuth, published: boolean) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/menu-models/" + menuId + "/publish/" + published, {}, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.map((menuModel: any) => menuModel.id === result.data.id ? result.data : menuModel)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createMenu: (menu: any, menuModelId: string, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/menu-models/" + menuModelId + "/menus", menu,{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.map((menuModel: any) => menuModel.id === result.data.id ? result.data : menuModel)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        deleteMenu: (menuModelId: string, menuId: string, reztoAuth: ReztoAuth) => {
            axios.delete(process.env.REACT_APP_ENGINE_BASE_URL + "/menu-models/" + menuModelId + "/menus/"  + menuId, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: deleteMenu(prev.menuModelStateSlice,  {menuModelId, menuId})
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        publishMenu: (menuModelId: string, menuId: string, reztoAuth: ReztoAuth, published: boolean) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + '/menu-models/' + menuModelId
                + "/menus/" + menuId + "/publish/" + published, {menuModelId, menuId: menuId}, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.map((menuModel: any) => menuModel.id === result.data.id ? result.data : menuModel)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        copyMenu: (fromMenuModelId: string, toMenuModelId: string, menuId: string, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/menu-models/" + fromMenuModelId +  "/menus/" + menuId + "/copy",
                {fromMenuModelId: fromMenuModelId, toMenuModelId: toMenuModelId, menuId: menuId},{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.map((menuModel: any) => menuModel.id === result.data.id ? result.data : menuModel)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createMenuCategory: (menuModelId: string, mainCategoryId: string, menuCategory: any, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + '/menu-models/' + menuModelId
                + "/menus/" + mainCategoryId + "/menu-categories", menuCategory,{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.map((menuModel: any) => menuModel.id === result.data.id ? result.data : menuModel)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        deleteMenuCategory: (menuModelId: string, mainCategoryId: string, menuCategoryId: string, reztoAuth: ReztoAuth) => {
            axios.delete(process.env.REACT_APP_ENGINE_BASE_URL + '/menu-models/' + menuModelId
                + "/menus/" + mainCategoryId + "/menu-categories/" + menuCategoryId, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                         ...deleteMenuCategory(prev, menuModelId, mainCategoryId, menuCategoryId)
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        publishMenuCategory: (menuModelId: string, mainCategoryId: string, menuCategoryId: string, reztoAuth: ReztoAuth, published: boolean) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + '/menu-models/' + menuModelId
                + "/menus/" + mainCategoryId + "/menu-categories/" + menuCategoryId + "/publish/" + published, {menuModelId, mainCategoryId, menuCategoryId}, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.map((menuModel: any) => menuModel.id === result.data.id ? result.data : menuModel)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        createMenuProduct: (menuModelId: string, menuId: string, menuCategoryId: string, menuProduct: any, reztoAuth: ReztoAuth) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + '/menu-models/'
                + menuModelId + '/menus/' + menuId + "/menu-categories/" + menuCategoryId + "/menu-products", menuProduct,{
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.map((menuModel: any) => menuModel.id === result.data.id ? result.data : menuModel)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        deleteMenuProduct: (menuModelId: string, menuId: string, menuCategoryId: string, menuProductId: string, reztoAuth: ReztoAuth) => {
            axios.delete(process.env.REACT_APP_ENGINE_BASE_URL + "/menu-models/" + menuModelId +
                "/menus/" + menuId + "/menu-categories/" + menuCategoryId + "/menu-products/" + menuProductId, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => ({
                        menuModelStateSlice: {
                            ...prev.menuModelStateSlice,
                            menuModels: prev.menuModelStateSlice.menuModels.map((menuModel: any) => menuModel.id === result.data.id ? result.data : menuModel)
                        }
                    }))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
        publishMenuProduct: (menuModelId: string, menuId: string, menuCategoryId: string, menuProductId: string, reztoAuth: ReztoAuth, published: boolean) => {
            axios.post(process.env.REACT_APP_ENGINE_BASE_URL + "/menu-models/" + menuModelId +
                "/menus/" + menuId + "/menu-categories/" + menuCategoryId + "/menu-products/" + menuProductId
                + "/publish/" + published, {}, {
                headers: GetHeaders(reztoAuth.token)
            }).then(
                (result) => {
                    set((prev) => (
                        {
                            ...setPublishMenuProduct(prev, menuModelId, menuId, menuCategoryId, menuProductId, published)
                        }
                    ))
                },
                (error) => {
                    console.log("error")
                },
            );
        },
    }
});
