import React, {useEffect, useState} from "react";

import MenuModelCard from './MenuModelCard';
import {MenuModel} from "../Model/Menu";
import {useParams} from "react-router-dom";
import FormDialog from "../Modal/FormDialog";
import MenuModelForm from "./forms/MenuModelForm";
import {assertTokenValidity} from "../common/ReztoToken";
import {useTranslation} from "react-i18next";
import {userReztoStore} from "../state/rootStore";


function MenuList() {

    const {reztoAuth, menuModelStateSlice} = userReztoStore();
    let menuModels = menuModelStateSlice.menuModels;
  
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (restaurantId) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    menuModelStateSlice.fetchMenuModels(restaurantId, reztoAuth);
                })

        }
    }, [reztoAuth, restaurantId]);


    return (
        <>

            <div className="flex flex-row  justify-between">
                <div className="flex flex-col justify-top">
                    <h1 className="text-header mb-2 mt-10">{t('MENUS_HEADER').toUpperCase()}</h1>
                    <h1 className="text-normal text-xl mb-2">{t('MENUS_SUB')}</h1>
                </div>
                <div className="flex flex-col justify-between pt-12 pb-3">
                    <button onClick={() => {
                        setCreateDialogOpen(true)
                    }}
                            className="dropdown-button h-10 mt-3">Ny menymodell
                    </button>
                </div>
            </div>
            <hr className="border-secondary border-t-2 border-solid"/>

            <div className="flex justify-between">
                {createDialogOpen &&
                <FormDialog title={"Ny menymodell"} height={200}
                            open={createDialogOpen} setOpen={setCreateDialogOpen}
                >
                    <MenuModelForm setCreateDialogOpen={setCreateDialogOpen}
                    />
                </FormDialog>
                }
            </div>


            <div className="flex flex-wrap pt-5 gap-3">
                {
                    menuModels.map((menuModel: MenuModel, index: number) => {
                        return <MenuModelCard menuModel={menuModel} key={index}/>
                    })
                }
            </div>

        </>

    );
}

export default MenuList;

