import create, {GetState, SetState} from "zustand";

import {createSelectedRestaurantSlice} from "./SelectedRestaurantState";
import {createCashierSlice} from "./ICashierSlice";
import {createAuthSlice} from "./AuthSlice";
import {createRestaurantChainAggregateSlice} from "./RestaurantChainAggregateSlice";
import {createStatisticSlice} from "./StatisticsSlice";
import {createReportSlice} from "./ReportSlice";
import {createProductSlice} from "./ProductSlice";
import {createOrderSlice} from "./OrdersSlice";
import {createKioskSlice} from "./KioskSlice";
import {createMenuModelSlice} from "./MenuSlice";
import {createFlowSlice} from "./FlowSlice";
import {createImageSlice} from "./ImageSlice";
import {createFilterSlice} from "./FilterSlice";
import {devtools} from "zustand/middleware";
import {createUserSlice} from "./UserSlice";


export type StoreSlice<T extends object, E extends object = T> = (
    set: SetState<E extends T ? E : E & T>,
    get: GetState<E extends T ? E : E & T>
) => T;




const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
    ...createSelectedRestaurantSlice(set, get),
    ...createCashierSlice(set, get),
    ...createAuthSlice(set,get),
    ...createRestaurantChainAggregateSlice(set, get),
    ...createStatisticSlice(set, get),
    ...createReportSlice(set,get),
    ...createProductSlice(set,get),
    ...createOrderSlice(set,get),
    ...createKioskSlice(set,get),
    ...createMenuModelSlice(set,get),
    ...createFlowSlice(set,get),
    ...createImageSlice(set,get),
    ...createFilterSlice(set,get),
    ...createUserSlice(set,get),
});


export function ResetStore(){
    userReztoStore.setState(
        {
            ...userReztoStore.getState(),
            statisticStateSlice: {
                ...userReztoStore.getState().statisticStateSlice,
                selectedDateInterval: undefined,
                currentDayReportSummary: undefined,
                intervalReportSummary: undefined,
            },
            reportStateSlice:{
                ...userReztoStore.getState().reportStateSlice,
                selectedDateInterval: undefined,
                reports: []
            },
            productStateSlice:{
                ...userReztoStore.getState().productStateSlice,
                products: [],
            },
            orderStateSlice:{
                ...userReztoStore.getState().orderStateSlice,
                orders: [],
                selectedReceiptInterval: undefined,
            },
            menuModelStateSlice:{
                ...userReztoStore.getState().menuModelStateSlice,
                menuModels: []
            },
            flowStateSlice:{
                ...userReztoStore.getState().flowStateSlice,
                flows: []
            },
            cashierState:{
                ...userReztoStore.getState().cashierState,
                cashiers: []
            },
        }
        , true)
}


export const userReztoStore = create(devtools(createRootSlice));
