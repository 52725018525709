import React, {useEffect} from "react";
import {Link, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import './App.css';
import Dashboard from './dashboard/dashboard';
import FlowSection from "./Flow/FlowSection";
import FlowList from "./Flow/FlowList";
import MenuList from './menu/MenuList';
import MenuModelPage from './menu/MenuModelPage';
import Navbar from './navbar/Navbar';
import EditProduct from './product/EditProduct';
import ProductList from './product/ProductList/ProductList';
import KioskList from "./Kiosk/KioskList";
import KioskPage from "./Kiosk/KioskPage";
import ReceiptList from "./Receipts/ReceiptList";
import ReportList from "./Reports/ReportList";
import ReztoDrawer from "./drawer/ReztoDrawer";
import StatisticsPage from "./Statistics/StatisticsPage";
import ScrollToTop from "./common/ScrollToTop";
import RestaurantChainPage from "./RestaurantChain/RestaurantChainPage";
import ImageBankPage from "./ImageBank/ImageBankPage";
import CashierPage from "./cashiers/CashierPage";
import {userReztoStore} from "./state/rootStore";
import {RestaurantChain} from "./Model/RestaurantChainAggregate";
import UserPage from "./Users/UserPage";
import KioskConfigurationPage from "./Kiosk/KioskConfigurationPage";

function Home() {
    const {reztoAuth} = userReztoStore();
    const {restaurantChainAggregateState} = userReztoStore();
    const {path} = useRouteMatch();
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const {restaurantChainId} = useParams<{ restaurantChainId: string }>();

    const {setSelectedRestaurant} = userReztoStore();

    useEffect(() => {
        if (restaurantId !== undefined && restaurantChainAggregateState.restaurants.length > 0) {
            let resChainId = restaurantChainAggregateState.restaurants.find((r: any) => r.id === restaurantId)?.restaurantChainId;
            if(resChainId ){
                setSelectedRestaurant(restaurantId, resChainId);
            }
        }

    }, [restaurantId, restaurantChainAggregateState])


    return (
        <>
            <div>


                <Navbar/>

                {restaurantChainId &&
                    <ScrollToTop>
                        <div className="flex-row flex justify-center z-0 pt-16 w-full">
                            <RestaurantChainPage/>
                        </div>
                    </ScrollToTop>
                }

                {restaurantId &&

                    <div className="flex-row flex wrapper-container lg:pr-0 sm:pr-10 z-0 pt-14 w-full">
                        <ReztoDrawer/>

                        <div className="content-header">

                            <ScrollToTop>
                                <Switch>
                                    <Route exact path={`${path}/restaurant-chains`}>
                                        <RestaurantChainPage/>
                                    </Route>
                                    <Route exact path={`${path}/dashboard`}>
                                        <Dashboard/>
                                    </Route>
                                    <Route exact path={`${path}/products`}>
                                        <ProductList/>
                                    </Route>
                                    <Route exact path={`${path}/products/:productId/edit`}>
                                        <EditProduct/>
                                    </Route>
                                    <Route exact path={`${path}/products/create`}>
                                        <EditProduct/>
                                    </Route>
                                    <Route exact path={`${path}/menu-models`}>
                                        <MenuList/>
                                    </Route>
                                    <Route exact path={`${path}/flows`}>
                                        <FlowList/>
                                    </Route>
                                    <Route exact path={`${path}/flows/:flowId`}>
                                        <FlowSection/>
                                    </Route>
                                    <Route exact path={`${path}/menu-models/:menuModelId`}>
                                        <MenuModelPage/>
                                    </Route>
                                    <Route exact path={`${path}/kiosks`}>
                                        <KioskList/>
                                    </Route>
                                    <Route exact path={`${path}/kiosks/:kioskId`}>
                                        <KioskPage/>
                                    </Route>
                                    <Route exact path={`${path}/kiosks/:kioskId/configuration`}>
                                        <KioskConfigurationPage/>
                                    </Route>
                                    <Route exact path={`${path}/receipts`}>
                                        <ReceiptList/>
                                    </Route>
                                    <Route exact path={`${path}/reports`}>
                                        <ReportList/>
                                    </Route>
                                    <Route exact path={`${path}/statistics`}>
                                        <StatisticsPage/>
                                    </Route>
                                    <Route exact path={`${path}/images`}>
                                        <ImageBankPage/>
                                    </Route>
                                    <Route exact path={`${path}/cashiers`}>
                                        <CashierPage/>
                                    </Route>
                                    <Route exact path={`${path}/users`}>
                                        <UserPage/>
                                    </Route>
                                </Switch>
                            </ScrollToTop>
                        </div>
                    </div>
                }
                {!restaurantId && !restaurantChainId &&

                    <div className="w-full h-screen flex flex-col justify-center items-center">

                        <div className="shadow-2xl flex flex-col p-20 m-10">
                            <div className="flex flex-row justify-center border-b-2 ml-10 mr-10"><h1
                                className="text-mainFont sm:text-2xl xl:text-4xl lg:text-2xl p-5">Välkommen, {reztoAuth.email}!</h1>
                            </div>

                            <div className="flex flex-col justify-center h-full mt-10">
                                <div className="flex flex-row gap-3 flex-wrap justify-center">
                                    {
                                        restaurantChainAggregateState.restaurantChains.map((r: RestaurantChain) => {
                                            return (
                                                <Link key={r.id} to={"/restaurant-chains/" + r.id}>
                                                    <div className="dropdown-button">
                                                        {r.name}
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                }
            </div>
        </>
    );
}


export default Home;
