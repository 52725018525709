import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";

import Paper from "@mui/material/Paper";
import {Flow, FlowCategory, FlowProduct} from "../Model/Flow";
import {Product} from "../Model/Product";
import FlowProductCard from "./FlowProductCard";
import FormDialog from "../Modal/FormDialog";
import FlowProductForm from "./Forms/FlowProductForm";
import {assertTokenValidity} from "../common/ReztoToken";
import CopyFlowCategoryForm from "./CopyFlowCategoryForm";
import ReztoSwitch from "../common/ReztoSwitch";
import ActionDropdown, {ACTION_DROPDOWN_ENUM} from "../common/ActionDropdown/ActionDropdown";
import ReorderForm from "../common/ReorderForm";
import ChangeNameForm from "../common/ChangeNameForm";
import DeleteForm from "../common/DeleteForm";
import {userReztoStore} from "../state/rootStore";

function FlowCategoryCard({flowCategoryId}: { flowCategoryId: string }) {

    const {reztoAuth, flowStateSlice} = userReztoStore();
    const {flowId} = useParams<{ flowId: string }>();
    const flows = flowStateSlice.flows;
    const {productStateSlice} = userReztoStore();

    const flow: Flow = flows.find((flow: Flow) => flow.id === flowId)!;
    const flowCategory: any = flow.flowCategories.find((fC: FlowCategory) => fC.id === flowCategoryId)
    const [actionDialog, setActionDialog] = useState('');
    const [flowProducts, setFlowProducts] = useState(flowCategory?.flowProducts.map(
        (flowProduct: FlowProduct) => {
            let product = productStateSlice.products.find((product: any) => product.id === flowProduct.productId);
            return {
                id: flowProduct.id,
                content: flowProduct,
                title: product ? product.name : flowProduct.productId
            }
        }));


    useEffect(() => {
        setFlowProducts(flowCategory.flowProducts.map(
            (flowProduct: FlowProduct) => {
                let product = productStateSlice.products.find((product: any) => product.id === flowProduct.productId);
                return {
                    id: flowProduct.id,
                    content: flowProduct,
                    title: product ? product.name : flowProduct.productId
                }
            }))

    }, [flowCategory.flowProducts.length, productStateSlice.products, flowCategory.flowProducts])


    if (!flow || !flowCategory || !flowProducts) {
        return (<></>)
    }

    function reorderFlowProducts() {
        const newFlowCategory: FlowCategory = {...flowCategory}
        newFlowCategory.flowProducts = flowProducts.map((fp: any) => fp.content)
        updateFlow(newFlowCategory);
    }

    const changeFlowCategoryName = (data: any) => {
        const newFlowCategory: FlowCategory = {...flowCategory}
        newFlowCategory.name = data.name;
        updateFlow(newFlowCategory);
    }

    function updateFlow(newFlowCategory: FlowCategory) {
        const newFlowCategories = flow.flowCategories.map(fC => fC.id === flowCategoryId ? newFlowCategory : fC)
        const newFlow: Flow = {...flow, flowCategories: newFlowCategories}
        assertTokenValidity(reztoAuth)
            .then((_) => {
                flowStateSlice.editFlow(newFlow, newFlow.id, reztoAuth);
            });
    }

    function onCopyFlow(data: any) {

        assertTokenValidity(reztoAuth)
            .then((_) => {
                flowStateSlice.copyFlowCategory(flowId, flowCategory.id, {
                    fromFlowId: flowId,
                    toFlowId: data.flowId,
                    flowCategoryId: flowCategory.id
                }, reztoAuth);
            });
    }

    function deleteFlowCategory(confirmed: boolean) {
        if (confirmed) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    flowStateSlice.deleteFlowCategory(flowId, flowCategory.id, reztoAuth);
                });
        }
    }

    function publish() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                flowStateSlice.publishFlowCategory(flow.id, flowCategory.id,
                    reztoAuth, !flowCategory.published);
            });
    }


    function dismissDialog() {
        setActionDialog('')
    }

    return (
        <>

            <Paper key={flowCategory.id} elevation={3}>
                <div className="border-2 border-opacity-50 flex flex-col justify-between rounded">
                    <div className="flex flex-row justify-between">


                        <div className="flex flex-col justify-center items-center ">
                            <p className="text-normal text-5xl p-3">
                                {flowCategory.name}
                            </p>

                        </div>

                        <div className="flex flex-row justify-end p-3">
                            <ActionDropdown actionDropdownItems={[
                                {
                                    icon: ACTION_DROPDOWN_ENUM.REORDER, label: "Ändra ordning", action: function () {
                                        setActionDialog('reorder')
                                    }
                                },
                                {
                                    icon: ACTION_DROPDOWN_ENUM.ADD, label: "Ny flödesprodukt", action: function () {
                                        setActionDialog('create')
                                    }
                                },
                                {
                                    icon: ACTION_DROPDOWN_ENUM.EDIT, label: "Byt namn", action: function () {
                                        setActionDialog('changeName')
                                    }
                                },
                                {
                                    icon: ACTION_DROPDOWN_ENUM.DELETE, label: "Radera", action: function () {
                                        setActionDialog('delete')
                                    }
                                },
                                {
                                    icon: ACTION_DROPDOWN_ENUM.COPY, label: "Kopiera", action: function () {
                                        setActionDialog('copy')
                                    }
                                },
                                {
                                    icon: flowCategory.published ? ACTION_DROPDOWN_ENUM.UNPUBLISH : ACTION_DROPDOWN_ENUM.PUBLISH, label: flowCategory.published ? "Avpublicera" : "Publicera", action: function () {
                                        publish()
                                    }
                                },
                            ]}/>

                            {actionDialog === 'reorder' &&
                                <ReorderForm title={"Ändra ordning"} onDismiss={dismissDialog}
                                             onClick={reorderFlowProducts}
                                             reorderItems={flowProducts} setReorderItems={setFlowProducts}/>}
                            {actionDialog === 'create' &&
                                <FormDialog title={"Ny flödesprodukt"}
                                            open={true} setOpen={dismissDialog}
                                >
                                    <FlowProductForm
                                        flowId={flowId}
                                        flowCategoryId={flowCategory.id}
                                        setCreateDialogOpen={dismissDialog}/>
                                </FormDialog>}
                            {actionDialog === 'changeName' &&
                                <ChangeNameForm title={"Byt namn på flödeskategori"}
                                                previousName={flowCategory.name} onClick={changeFlowCategoryName}
                                                onDismiss={dismissDialog}/>}
                            {actionDialog === 'delete' &&
                                <DeleteForm confirmText={"Vill du verkligen ta bort flödeskategorin?"}
                                            onClick={deleteFlowCategory} onDismiss={dismissDialog}/>}
                            {actionDialog === 'copy' &&
                                <CopyFlowCategoryForm onClick={onCopyFlow} onDismiss={dismissDialog}/>}
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap ">
                        {flowCategory.flowProducts.map((flowProduct: FlowProduct, index: number) => {
                            let product = productStateSlice.products.find((product: Product) => product.id === flowProduct.productId);
                            if (product) {
                                return (<FlowProductCard
                                    key={flowProduct.id}
                                    flowProduct={flowProduct}
                                    flowId={flow.id}
                                    flowCategoryId={flowCategory.id}
                                />)
                            }

                        })
                        }
                    </div>
                </div>

            </Paper>
        </>

    );
}

export default FlowCategoryCard;

