import 'firebase/storage';
import React, {useEffect, useState} from 'react';

import {useHistory, useParams} from "react-router-dom";
import {assertTokenValidity} from "../../common/ReztoToken";
import FormDialog from "../../Modal/FormDialog";
import {Menu, MenuModel} from "../../Model/Menu";
import MenuForm from "../forms/MenuForm";
import ReztoSwitch from '../../common/ReztoSwitch';
import ActionDropdown, {ACTION_DROPDOWN_ENUM} from "../../common/ActionDropdown/ActionDropdown";
import ReorderForm from "../../common/ReorderForm";
import ChangeNameForm from "../../common/ChangeNameForm";
import DeleteForm from "../../common/DeleteForm";
import {userReztoStore} from "../../state/rootStore";

function MenuModelHeader() {
    let {menuModelId} = useParams<{ menuModelId: string }>();
    const {menuModelStateSlice} = userReztoStore();
    let menuModels = menuModelStateSlice.menuModels
    let menuModel: MenuModel = menuModels.find((menu: MenuModel) => menu.id === menuModelId)!;

    const {reztoAuth} = userReztoStore();
    const history = useHistory();
    const [actionDialog, setActionDialog] = useState('');
    const [menus, setMenus] = useState(menuModel.menus.map((menu: Menu) => ({
        id: menu.id,
        content: menu,
        title: menu.displayName
    })));

    useEffect(() => {
        setMenus(menuModel.menus.map((menu: Menu) => ({
            id: menu.id,
            content: menu,
            title: menu.displayName
        })))
    }, [menuModel, menuModel.menus.length])

    if (!menuModel) {
        return (<></>);
    }


    const onSub = (data: any) => {
        const newMenuModel = {...menuModel, name: data.name}
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.editMenuModel(newMenuModel, reztoAuth);
            });
    }


    function updateMenuOrder() {
        const newMenuModel = {...menuModel, menus: menus.map(reorderItem => reorderItem.content)}
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.editMenuModel(newMenuModel, reztoAuth);
            });
    }

    function deleteMenuModel(confirmed: boolean) {
        if (confirmed) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    menuModelStateSlice.deleteMenuModel(menuModel.id, reztoAuth);
                    history.push("/restaurants/" + menuModel.restaurantId + "/menu-models");
                });
        }
    }


    function dismissDialog() {
        setActionDialog('')
    }

    function publish() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.publishMenuModel(menuModel.id, reztoAuth, !menuModel.published);
            });
    }

    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col justify-center items-center ">
                        <h1 className="text-7xl p-3 text-primary p-3">{menuModel.name}</h1>
                    </div>

                    <div className="flex flex-row justify-end">
                        <div className="flex flex-row justify-end p-3">
                            <ActionDropdown actionDropdownItems={[
                                {
                                    icon: ACTION_DROPDOWN_ENUM.REORDER, label: "Ändra ordning", action: function () {
                                        setActionDialog('reorder')
                                    }
                                },
                                {
                                    icon: ACTION_DROPDOWN_ENUM.ADD, label: "Ny meny", action: function () {
                                        setActionDialog('create')
                                    }
                                },
                                {
                                    icon: ACTION_DROPDOWN_ENUM.EDIT, label: "Byt namn", action: function () {
                                        setActionDialog('changeName')
                                    }
                                },
                                {
                                    icon: ACTION_DROPDOWN_ENUM.DELETE, label: "Radera", action: function () {
                                        setActionDialog('delete')
                                    }
                                },
                                {
                                    icon: menuModel.published ? ACTION_DROPDOWN_ENUM.UNPUBLISH : ACTION_DROPDOWN_ENUM.PUBLISH,
                                    label: menuModel.published ? "Avpublicera" : "Publicera",
                                    action: function () {
                                        publish()
                                    }
                                },
                            ]}/>

                            {actionDialog === 'reorder' &&
                                <ReorderForm title={"Ändra ordning"} onDismiss={dismissDialog}
                                             onClick={updateMenuOrder}
                                             reorderItems={menus} setReorderItems={setMenus}/>}
                            {actionDialog === 'create' &&

                                <FormDialog height={200} title={"Ny meny"}
                                            open={true} setOpen={dismissDialog}>
                                    <MenuForm setCreateDialogOpen={dismissDialog} menuModelId={menuModel.id}/>
                                </FormDialog>}
                            {actionDialog === 'changeName' &&
                                <ChangeNameForm title={"Byt namn på meny"}
                                                previousName={menuModel.name} onClick={onSub}
                                                onDismiss={dismissDialog}/>}
                            {actionDialog === 'delete' &&
                                <DeleteForm confirmText={"Vill du verkligen ta bort menyn?"}
                                            onClick={deleteMenuModel} onDismiss={dismissDialog}/>}
                        </div>
                    </div>
                </div>
                <hr/>
            </div>
        </>

    )
}

export default MenuModelHeader;
