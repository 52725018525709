import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useParams} from "react-router-dom";
import ReztoDateRangePicker from "../common/ReztoDateRangePicker";
import {assertTokenValidity} from "../common/ReztoToken";
import ReceiptCard from "./ReceiptCard";
import ReceiptFilter from "./ReceiptFilter";
import LazyLoad from "react-lazyload";
import InfiniteScroll from "react-infinite-scroll-component";
import {useWindowSize} from "../drawer/ReztoDrawer";
import './ReceiptList.css';
import {userReztoStore} from "../state/rootStore";
import {Order} from "../Model/Order";

function ReceiptList() {
    const {t} = useTranslation();
    const {reztoAuth, orderStateSlice} = userReztoStore();
    const originalOrderState = {...orderStateSlice};
    const [filteredOrders, setOrders] = useState(originalOrderState.orders);
  
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const previousSelectedDateInterval = originalOrderState.selectedReceiptInterval ? originalOrderState.selectedReceiptInterval :
        {fromDate: new Date().toISOString(), toDate: new Date().toISOString()};
    const [page, setPage] = useState(1);
    const windowSize = useWindowSize();

    function onDateRangeChange(value: any) {
        fetchFirstSetOfOrders(value[0]?.toISOString().split("T")[0],
            value[1]?.toISOString().split("T")[0]);
    }

    useEffect(() => {
        fetchFirstSetOfOrders(previousSelectedDateInterval.fromDate.split("T")[0],
            previousSelectedDateInterval.toDate.split("T")[0]);
    }, [])

    function fetchFirstSetOfOrders(from: string, to: string) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                orderStateSlice.fetchOrdersInterval(restaurantId, reztoAuth,
                    from, to, 0, Math.ceil(windowSize.height / 50));
            });
    }

    function fetchMore() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                orderStateSlice.fetchOrdersIntervalPagination(restaurantId, reztoAuth,
                    previousSelectedDateInterval.fromDate.split("T")[0],
                    previousSelectedDateInterval.toDate.split("T")[0], page + 1, 18);
            });
        setPage(page + 1)
    }

    useEffect(() => {
        setOrders(originalOrderState.orders)
    }, [originalOrderState])


    return (

        <>

            <div className="receipt-list-header">
                <div className="flex flex-col justify-top">
                    <h1 className="text-header mb-2 mt-10 font-bold">{t('RECEIPTS').toUpperCase()}</h1>
                    <h1 className="text-normal text-xl mb-2 font-light text-mainFont">{t('RECEIPTS_SUB_HEADER')}</h1>
                    <div className="flex flex-row w-2 justify-start">
                        <ReceiptFilter setFilteredOrders={setOrders} originalOrders={originalOrderState.orders}/>
                    </div>
                </div>
                <div className="flex flex-col justify-between pt-12 pb-3">
                    <ReztoDateRangePicker onChange={onDateRangeChange}
                                        previousSelectedDateInterval={previousSelectedDateInterval}/>
                </div>
            </div>
            
            <hr className="border-secondary border-t-2 border-solid mt-5 mb-5"/>
            
            {filteredOrders.length === 0 &&
                <div className="flex flex-col justify-center text-center absolute" style={{left: '30%', top: '50%'}}>
                    <h1 className="text-normal text-2xl align-middle">
                        {t("NO_RECEIPTS_FOUND")}</h1>
                </div>
            }

            <>
                <InfiniteScroll
                    dataLength={filteredOrders.length}
                    next={fetchMore}
                    hasMore={true}
                    loader={<h4>Loading...</h4>}
                >
                    {filteredOrders.map((order: Order, index: number) => {
                        return (
                            <div className="pb-2" key={order.id + index}>
                                <LazyLoad height={50}>
                                    <ReceiptCard orderId={order.id}/>
                                </LazyLoad>
                            </div>
                        )
                    })}
                </InfiniteScroll>
            </>
        </>


    );
}

export default ReceiptList;

