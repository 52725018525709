import React, {useEffect, useMemo, useState} from "react";
import {userReztoStore} from "../state/rootStore";
import Navbar from "../navbar/Navbar";
import './UserPage.css';
import {UserState} from "../state/UserSlice";
import {ReztoAuth} from "../Model/AuthModel";
import {ReztoAuthState} from "../state/AuthSlice";
import {Checkbox, Group, TextInput} from "@mantine/core";
import Button from "@mui/material/Button";
import InputField from "../common/InputField";
import {useForm} from "react-hook-form";
import UserForm from "./UserForm";
import {Switch, useHistory} from "react-router-dom";

export default function UserPage() {

    const {userStateSlice, reztoAuth} = userReztoStore();
    let userState: UserState = userStateSlice;
    let auth: ReztoAuthState = reztoAuth;

    const history = useHistory();

    const [isForm, setIsForm] = useState(false);

    useEffect(() => {
        userState.fetchUsers(reztoAuth);
    }, [auth.email])


    return (
        <>
            <Navbar/>
            <div className="flex flex-col mt-32 items-center">
                <h1 className="select-none text-black text-5xl user-page-title p-5">ANVÄNDARE</h1>
                <div className="w-1/3 border-2 p-3 ">
                    <>
                        <div className="flex flex-row justify-end w-full">
                            <h1 onClick={() => history.push("/users/form")} className="dropdown-button m-3">Ny
                                användare</h1>
                        </div>
                        <div className="flex flex-row flex-wrap gap-2 p-10">
                            {
                                userState.users.map(user => {
                                    return <h1 className="user-page-list-item border-2 p-2 ">{user.email}</h1>
                                })
                            }
                        </div>
                    </>
                </div>
            </div>
        </>
    );
}
