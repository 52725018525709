import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
export default function ReztoTooltip({ title, children }: { title: string, children: any }) {

    const useStyles = makeStyles({
        tooltip: {
            fontSize: "1em",
        },
    });

    const classes = useStyles();

    return (
        <Tooltip classes={{ tooltip: classes.tooltip }} title={title} placement="top" arrow>
            {children}
        </Tooltip>

    )
}
