import React, {useState} from 'react'
import './ReztoImage.css';
export default function ReztoImage({imageUrl, height, width, classes}:
                                       { imageUrl: any, height: any, width: any, classes?: string }) {
    const [loaded, setLoaded] = useState(false);
    const imageStyle = !loaded ? {display: "none"} : {};

    return (
        <>
            {!loaded && <div style={{width: width, height: height}} className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>}
            <div style={imageStyle}>
                <img onLoad={() => setLoaded(true)} className={classes}
                     style={{height: height,
                         width: width, boxShadow:'0 10px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)', objectFit: 'cover', borderRadius: '500px'}} src={imageUrl}/>
            </div>
        </>
    )
}
