import Paper from '@mui/material/Paper';
import 'firebase/storage';
import React, {useEffect, useState} from 'react';
import LazyLoad from 'react-lazyload';
import {useParams} from "react-router-dom";
import ReztoSwitch from '../common/ReztoSwitch';
import {assertTokenValidity} from "../common/ReztoToken";
import FormDialog from "../Modal/FormDialog";
import {Menu, MenuCategory, MenuModel} from "../Model/Menu";
import MenuCategoryForm from "./forms/MenuCategoryForm";
import './menu.css';
import MenuCategoryCard from "./MenuCategoryCard";
import ActionDropdown, {ACTION_DROPDOWN_ENUM} from "../common/ActionDropdown/ActionDropdown";
import ReorderForm from "../common/ReorderForm";
import ChangeNameForm from "../common/ChangeNameForm";
import DeleteForm from "../common/DeleteForm";
import CopyMenuForm from "./CopyMenuForm";
import {userReztoStore} from "../state/rootStore";

function MenuCard({menuId}: { menuId: string, index: number }) {

    const {reztoAuth, menuModelStateSlice} = userReztoStore();
    let {menuModelId} = useParams<{ menuModelId: string }>();
    let menuModels: MenuModel[] = menuModelStateSlice.menuModels;
    const [actionDialog, setActionDialog] = useState('');
    const menuModel: any = menuModels.find((menuModel: MenuModel) => menuModel.id === menuModelId);
    const menu: Menu = menuModel.menus.find((menu: Menu) => menu.id === menuId);
    const [menuCategories, setMenuCategories] = useState(menu.menuCategories.map((menuCategory: MenuCategory) => ({
        id: menuCategory.id,
        content: menuCategory,
        title: menuCategory.name
    })));

    useEffect(() => {
        setMenuCategories(menu.menuCategories.map((menuCategory: MenuCategory) => ({
            id: menuCategory.id,
            content: menuCategory,
            title: menuCategory.name
        })))
    }, [menu, menuModel, menu.menuCategories.length])


    function updateMenuModel() {
        const newMenu = {...menuModel.menus.find((m: Menu) => m.id === menu.id)}
        newMenu.menuCategories = menuCategories.map((mc: any) => mc.content)
        let newMenuModel = {
            ...menuModel,
            menus: menuModel.menus.map((menu: Menu) => menu.id === menuId ? newMenu : menu)
        }
        saveChanges(newMenuModel)
    }


    const onMenuNameChange = (data: any) => {
        const newMenu: Menu = {...menuModel.menus.find((m: Menu) => m.id === menu.id)}
        newMenu.displayName = data.name;
        let newMenuModel = {
            ...menuModel,
            menus: menuModel.menus.map((menu: Menu) => menu.id === menuId ? newMenu : menu)
        }
        saveChanges(newMenuModel)
    }

    function saveChanges(newMenuModel: MenuModel) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.editMenuModel(newMenuModel, reztoAuth);
            });
    }

    if (!menu) {
        return (<></>);
    }

    function deleteMenu(confirmed: boolean) {
        if (confirmed) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    menuModelStateSlice.deleteMenu(menuModelId, menu.id, reztoAuth);
                });
        }
    }

    function publish() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.publishMenu(menuModelId, menuId, reztoAuth, !menu.published);
            });
    }

    function onCopyMenu(data: any) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.copyMenu(menuModelId, data.menuModelId, menuId, reztoAuth);
            });
    }

    function dismissDialog() {
        setActionDialog('')
    }

    return (
        <Paper key={menu.id} elevation={3}>
            <div className="border-2 border-opacity-50 flex flex-col justify-between rounded">
                <div className="flex flex-row justify-between">

                    <div className="flex flex-row justify-start items-center w-full">
                        <p className="p-3 text-large">
                            {menu.displayName}
                        </p>
                    </div>

                    <div className="flex flex-row justify-end p-3">
                        <ActionDropdown actionDropdownItems={[
                            {
                                icon: ACTION_DROPDOWN_ENUM.REORDER, label: "Ändra ordning", action: function () {
                                    setActionDialog('reorder')
                                }
                            },
                            {
                                icon: ACTION_DROPDOWN_ENUM.ADD, label: "Ny menykategori", action: function () {
                                    setActionDialog('create')
                                }
                            },
                            {
                                icon: ACTION_DROPDOWN_ENUM.EDIT, label: "Byt namn", action: function () {
                                    setActionDialog('changeName')
                                }
                            },
                            {
                                icon: ACTION_DROPDOWN_ENUM.DELETE, label: "Radera", action: function () {
                                    setActionDialog('delete')
                                }
                            },
                            {
                                icon: ACTION_DROPDOWN_ENUM.COPY, label: "Kopiera", action: function () {
                                    setActionDialog('copy')
                                }
                            },
                            {icon: menu.published ? ACTION_DROPDOWN_ENUM.UNPUBLISH :  ACTION_DROPDOWN_ENUM.PUBLISH, label: menu.published ? "Avpublicera" : "Publicera", action: function(){publish()}},
                        ]}/>
                    </div>

                    {actionDialog === 'reorder' &&
                        <ReorderForm title={"Ändra ordning på menykategorier"} onDismiss={dismissDialog}
                                     onClick={updateMenuModel}
                                     reorderItems={menuCategories} setReorderItems={setMenuCategories}/>}
                    {actionDialog === 'create' &&
                        <FormDialog height={200} title={"Ny menykategori"}
                                    open={true} setOpen={dismissDialog}>
                            <MenuCategoryForm setCreateDialogOpen={dismissDialog}
                                              menuModelId={menuModel.id}
                                              menuId={menu.id}/>
                        </FormDialog>}
                    {actionDialog === 'changeName' &&
                        <ChangeNameForm title={"Byt namn på meny"}
                                        previousName={menu.displayName} onClick={onMenuNameChange}
                                        onDismiss={dismissDialog}/>}
                    {actionDialog === 'delete' &&
                        <DeleteForm confirmText={"Vill du verkligen ta bort menyn?"}
                                    onClick={deleteMenu} onDismiss={dismissDialog}/>}
                    {actionDialog === 'copy' &&
                        <CopyMenuForm onClick={onCopyMenu} onDismiss={dismissDialog}/>}
                </div>

                <hr className="m-3"/>

                <div className="flex flex-col gap-10">
                    {
                        menu.menuCategories.map((menuCategory: MenuCategory) => {
                            return (
                                <LazyLoad key={menuCategory.id} height={200}>
                                    <MenuCategoryCard menuCategory={menuCategory}
                                                      menuModelId={menuModelId}
                                                      menuId={menu.id}
                                    />
                                </LazyLoad>
                            )
                        })
                    }
                </div>

            </div>
        </Paper>
    )
}

export default MenuCard;
