import {Restaurant, RestaurantChain} from "../Model/RestaurantChainAggregate";
import {StoreSlice} from "./rootStore";
import axios from "axios";
import {ReztoAuth} from "../Model/AuthModel";

export interface RestaurantChainAggregateState{
    restaurantChains: RestaurantChain[];
    restaurants: Restaurant[];
    fetchRestaurantChainAggregate: (reztoAuth: ReztoAuth) => void;
}

interface RestaurantChainAggregateSlice{
    restaurantChainAggregateState: RestaurantChainAggregateState
}

export const createRestaurantChainAggregateSlice: StoreSlice<RestaurantChainAggregateSlice> = (set, get) => ({
    restaurantChainAggregateState: {
        restaurantChains: [],
        restaurants: [],
        fetchRestaurantChainAggregate: (reztoAuth) => {

            const config = {
                headers: {Authorization: `Bearer ${reztoAuth.token}`}
            };
            axios.get(  process.env.REACT_APP_ENGINE_BASE_URL + '/aggregate/restaurantchain',  {
                headers: config.headers
            }).then(
                (result) => {
                    set((prev) => ({restaurantChainAggregateState: {
                        ...prev.restaurantChainAggregateState,
                            ...result.data
                        }}))
                },
                (error) => {
                    console.log("error")
                },
            );
        }
    },

});
