import Paper from '@mui/material/Paper'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import {Flow} from "../Model/Flow";
import {userReztoStore} from "../state/rootStore";

export default function FlowSelectionCard({ prevFlowId, form, flowType }: {prevFlowId?: string, form: any, flowType: string}) {
    const {flowStateSlice} = userReztoStore();
    let flows: Flow[] = flowStateSlice.flows;
    flows = flows.filter(f => f.flowType === flowType)
    let prevFlow = flows.find((flow: Flow) => flow.id === prevFlowId)
    const [flow, setFlow] = useState(prevFlow);
    const [selection, setSelection] = useState('');


    useEffect(() => {
        form.setFieldValue(flowType, prevFlow);
    }, [prevFlowId]);


    return (
        <>
            <Paper onClick={() => { setSelection('product') }}
                   style={{
                       width: '100%', height: '100%', display: 'flex',
                       cursor: 'pointer',
                       alignItems: 'center',
                       justifyContent: 'space-around', flexDirection: 'column'
                   }}>
                {flow &&
                    <div className="mr-auto ml-auto mt-auto mb-auto">
                        <h1 className="z-30 relative bottom-0 text-black">{flow.displayTitle}</h1>
                    </div>
                }

                {!flow &&
                    <>
                        <h2 style={{ marginLeft: 'auto', marginRight: 'auto' }}>Välj flöde</h2>
                    </>
                }

            </Paper>
            {selection === 'product' &&
                <div>
                    <div className="absolute z-50 overflow-y-scroll flex flex-col gap-2 p-5
                  bg-secondary top-0 left-0 w-full min-h-full max-h-96">
                        {
                            flows.filter((flow: Flow) => flow.published).map((flow: Flow) => {
                                return (
                                    <h1 key={flow.id} onClick={() => {
                                        setFlow(flow);
                                        form.setFieldValue(flowType, flow);
                                        setSelection('');

                                    }} className="dropdown-button">{flow.displayTitle}</h1>
                                )
                            })
                        }
                    </div>
                    <button className="dropdown-button absolute z-50 bottom-1 left-1 " onClick={() => {setSelection('')}}>Tillbaka</button>
                </div>
            }
        </>

    )
}
