import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {assertTokenValidity} from "../common/ReztoToken";
import FormDialog from "../Modal/FormDialog";
import InputField from "../common/InputField";
import {useForm} from "react-hook-form";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ConfirmDialog from "../Modal/ConfirmDialog";
import {userReztoStore} from "../state/rootStore";
import {Cashier} from "../Model/Cashier";

export default function CashierPage() {

    const {cashierState} = userReztoStore();
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const {t} = useTranslation();
    const {reztoAuth} = userReztoStore()
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedCashierId, setSelectedCashierId] = useState("");

    useEffect(() => {

        assertTokenValidity(reztoAuth)
            .then((_) => {
                cashierState.fetchCashiers(restaurantId, reztoAuth);
            });

    }, [restaurantId])

    const {handleSubmit, control} = useForm();



    const onSub = (data: any) => {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                cashierState.createCashier( {
                    name: data.name,
                    restaurantId: restaurantId,
                    pin: data.pin
                }, reztoAuth);
            });

        setCreateDialogOpen(false);
    }

    function deleteCashier() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                cashierState.deleteCashier(selectedCashierId, reztoAuth);
            });
    }


    return (
        <>
            <div className="flex flex-row justify-between items-center">

                <h1 className="text-header mb-2 mt-10 font-bold">{t('CASHIER_PAGE_HEADER').toUpperCase()}</h1>
                <button className="dropdown-button h-10" onClick={() => {
                    setCreateDialogOpen(true)
                }}>Ny kassör
                </button>
            </div>
            {createDialogOpen &&
                <FormDialog title={"Ny kassör"} height={300}
                            open={createDialogOpen} setOpen={setCreateDialogOpen}
                >
                    <div className="p-5">
                        <form onSubmit={handleSubmit(onSub)}>
                            <InputField rules={{ required: 'Vänligen fyll i ett namn'}} name="name" title="Namn" control={control}/>
                            <InputField rules={{ required: 'Vänligen fyll i en pinkod', pattern: {
                                    value: /^[0-9]{6}$/i,
                                    message: "Pinkoden måste bestå av exakt 6 siffror"
                                } }}  name="pin" title="Pinkod" control={control}/>

                            <div className="flex flex-row justify-between pr-5 pl-5 mt-10">
                                <button type="button"
                                        onClick={() => setCreateDialogOpen(false)}
                                        className="dropdown-button">Avbryt
                                </button>
                                <button type="submit" className="dropdown-button">Spara</button>
                            </div>
                        </form>
                    </div>
                </FormDialog>
            }
            <ConfirmDialog callBack={() => {
                deleteCashier();
            }} title="Radera kassör" open={deleteDialogOpen} setOpen={setDeleteDialogOpen}/>


            {(cashierState.cashiers && cashierState.cashiers.length > 0) &&
                <div className="flex flex-row flex-wrap gap-3">
                    {
                        cashierState.cashiers.map((c: Cashier) =>
                            <div key={c.id} className="border-2 rounded-2xl p-3 w-1/6">

                                <h1 className="text-black text-2xl w-36">
                                    {c.name}
                                </h1>
                                <div className="text-4xl" >
                                    <DeleteRoundedIcon onClick={() => {
                                        setSelectedCashierId(c.id);
                                        setDeleteDialogOpen(true);
                                    }} style={{color: 'gray', fontSize: 30, cursor: 'pointer'}}/>
                                </div>
                            </div>
                        )
                    }
                </div>
            }



        </>
    );
}