
import React from 'react'
import ReztoTooltip from '../ReztoTooltip'
export default function DeleteIcon({ title, color, callBack }: { title: string, color: any, callBack: any }) {


    return (
        <div className="tooltip cursor-pointer icon-container" onClick={() => callBack()}>
            <ReztoTooltip title={title}>
                <svg className="icon-svg" color={color} viewBox="0 0 14 18" version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Two-Tone" transform="translate(-105.000000, -245.000000)">
                            <g id="Action" transform="translate(100.000000, 100.000000)">
                                <g id="Two-Tone-/-Action-/-delete_outline" transform="translate(0.000000, 142.000000)">
                                    <g>
                                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                        <path
                                            d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 Z M8,9 L16,9 L16,19 L8,19 L8,9 Z M15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 L15.5,4 Z"
                                            id="🔹-Primary-Color" fill="#1D1D1D"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </ReztoTooltip>
        </div>


    )
}
