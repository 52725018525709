import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import {assertTokenValidity} from "../common/ReztoToken";
import {ReztoEngineImage} from "../Model/ReztoEngineImage";
import './ImageBankPage.css'
import ImageBankCard from "./ImageBankCard";
import {userReztoStore} from "../state/rootStore";

export default function ImageBankPage() {

    const {t} = useTranslation();
    const {selectedRestaurant} = userReztoStore();
    const {reztoAuth, imageStateSlice} = userReztoStore();
    const images: ReztoEngineImage[] = imageStateSlice.images;

  

    useEffect(() => {
        if (selectedRestaurant) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    if (selectedRestaurant.restaurantChainId) {
                        imageStateSlice.fetchImages([selectedRestaurant.restaurantChainId],
                            reztoAuth);
                    }

                });
        }
    }, [selectedRestaurant])

    return (
        <>
            <div className="flex justify-between">
                <h1 className="text-header m-2 mt-10">{t('IMAGE_BANK_HEADER')}</h1>
            </div>

            <hr className="border-secondary border-t-2 border-solid"/>
            {images.length === 0 &&
                <h1 className="p-3 text-2xl text-black">Inga bilder ännu!</h1>
            }
            {images.length > 0 &&
                <div className="flex flex-row flex-wrap gap-5 shadow-2xl p-5 mt-5">
                    {
                        images.map(i => {
                            return <ImageBankCard image={i}/>
                        })
                    }
                </div>
            }


        </>
    );
}