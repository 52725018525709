import * as React from 'react';
import { useState } from 'react';
import ConfirmDialog from "../Modal/ConfirmDialog";
import DeleteIcon from "./Icons/DeleteIcon";

export default function CustomDeleteForm({ title, confirmText, onClick, children }
    : { title: any, confirmText: any, onClick: any, children?: any }) {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const onSubmit = (confirmed: boolean) => {
        onClick(confirmed);
        setDeleteDialogOpen(false);
    }
    return (
        <>
            {children &&
            <div onClick={() => setDeleteDialogOpen(true)}>
                { children }
            </div>
               
            }
            {!children &&
                <DeleteIcon
                    title={title}
                    callBack={function () {
                        setDeleteDialogOpen(true)
                    }} color="#1f2329" />
            }

            {deleteDialogOpen &&
                <ConfirmDialog
                    title={confirmText}
                    callBack={(confirmed: boolean) => {
                        onSubmit(confirmed)
                    }}
                    open={deleteDialogOpen}
                    setOpen={setDeleteDialogOpen}
                />
            }
        </>
    );
}