export const PRODUCT_DISPLAY_TYPE = { "Maträtt": "MEAL", "Dricka": "DRINK", "Tillbehör": "ADDON", "Subprodukt (Inkluderad i rätt)": "SUB_PRODUCT" };

export const TEMPLATE_TYPE = { "Ingen": "NONE", "Template Hamburgare": "HAMBURGER", "Template Lunch": "LUNCH", "Template Pizza": "PIZZA" };

export const PRODUCT_PAYMENT_TYPE = { "Maträtt": "PRODUCT", "Presentkort": "GIFTCARD" };

export const VAT_TYPE = { "25 %": "VAT_25", "12 %": "VAT_12", "6 %": "VAT_6", "0 %": "VAT_0" };

export const VAT_PRODUCT_TYPE = { "Ingen": "NONE", "Mat": "FOOD", "Dricka": "DRINK", "Öl": "BEER", "Cider": "CIDER", "Vin": "WINE", "Sprit": "LIQUEUR" };

export const ALLERGIES = {
    "Glutenfri": "GLUTEN_FREE",
    "Vegan": "VEGAN",
    "Vegetarisk": "VEGETARIAN",
    "Laktosfri": "LACTOSE_FREE",
    "Nötfri": "NUT_FREE",
    "Sesamfri": "SESAME_FREE",
    "Äggfri": "EGG_FREE",
    "Fiskfri": "FISH_FREE",
    "Fläskfri": "PORK_FREE",
    "Sojafri": "SOY_FREE",
    "Sellerifri": "CELERY_FREE",
    "Senapsfri": "MUSTARD_FREE",
    "Jordnötsfri": "PEANUT_FREE",
    "Fri från Svaveloxid- & sulfit": "SULFUR_SULPHITE_FREE"
};


export const REZTO_ACCESS_ROLES = {
    "RESTAURANT_CHAIN_OWNER": "RESTAURANT_CHAIN_OWNER",
    "RESTAURANT_OWNER": "RESTAURANT_OWNER",
}

export const REZTO_ROLE = {
    "REZTO_SUPER_ADMIN": "REZTO_SUPER_ADMIN",
    "USER": "USER"
}

export const FORM_MODAL_TYPE = {
    "MENU_MODEL": "MENU_MODEL",
    "MAIN_CATEGORY": "MAIN_CATEGORY",
    "MENU_CATEGORY": "MENU_CATEGORY",
    "MENU_PRODUCT": "MENU_PRODUCT",
    "FLOW": "FLOW",
    "FLOW_CATEGORY": "FLOW_CATEGORY",
    "FLOW_PRODUCT": "FLOW_PRODUCT"

}

export const FORM_MODAL_STATE = {
    "NONE": "NONE",
    "INITIAL": "INITIAL",
    "IN_PROGRESS": "IN_PROGRESS",
    "FULFILLED": "FULFILLED",
    "ERROR": "ERROR"
}


export const FLOW_TYPE = {
    "Upsellflöde - Min Order" : "UPSELLFLOW_MYORDER",
    "Produktflöde - Skroll" : "PRODUCTFLOW_SCROLL"
}


export const PRICE_CATEGORY = {
    "Äta här" : "ORIGINAL",
    "Take away" : "TAKEAWAY"
}