import React, {useEffect, useState} from 'react'
import ProductSelectionCard from '../../common/ProductSelection/ProductSelectionCard';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import {Flow, FlowCategory, FlowProduct} from "../../Model/Flow";
import {assertTokenValidity} from "../../common/ReztoToken";
import {userReztoStore} from "../../state/rootStore";
import { useForm } from '@mantine/form';

export default function FlowProductForm({
                                            flowId, flowCategoryId
                                            , setCreateDialogOpen, flowProduct
                                        }:
                                            {
                                                flowId: string, flowCategoryId: string,
                                                setCreateDialogOpen: any,
                                                flowProduct?: FlowProduct
                                            }) {
    const {reztoAuth, flowStateSlice} = userReztoStore();
    const [checked, setChecked] = useState(false);
    const flows = flowStateSlice.flows;

    const form = useForm({
        initialValues: {
            flowProduct,
            checked
        }
    });
  


    useEffect(() => {
        if (flowProduct) {
          setChecked(flowProduct?.includedInMeal);
          form.setFieldValue('checked',  flowProduct.includedInMeal);
        }

    }, [flowProduct]);


    const log = (data: any) => {
        console.log(data);
        if (flowProduct && data.product) {
            let flowToUpdate = {...flows.find((flow: Flow) => flow.id === flowId)};
            let flowProductToUpdate = flowToUpdate.flowCategories!
                .find((flowCategory: FlowCategory) => flowCategory.id === flowCategoryId)!.flowProducts
                .find((fp: FlowProduct) => fp.id === flowProduct.id)

            flowProductToUpdate!.includedInMeal = checked;
            flowProductToUpdate!.productId = data.product.id;

            if(flowProductToUpdate.productId){
                assertTokenValidity(reztoAuth)
                    .then((_) => {
                        flowStateSlice.editFlow(flowToUpdate,
                            flowId,
                            reztoAuth);
                        setCreateDialogOpen(false)
                    });
            }
        } else {
            if(data.product){
                assertTokenValidity(reztoAuth)
                    .then((_) => {
                        flowStateSlice.createFlowProduct( flowId,
                            flowCategoryId,
                            {
                                productId: data.product.id,
                                displayOrder: 0,
                                isIncludedInMeal: checked
                            },
                            reztoAuth);
                        setCreateDialogOpen(false)
                    });
            }
        }

    }

    return (
        <div className="p-5 h-full">
            <form className="h-full" onSubmit={form.onSubmit((values) => log(values))}>
                <div className="flex flex-col h-full">

                    <div className="h-full pl-20 pr-20 pt-2">
                        <ProductSelectionCard prevProductId={flowProduct?.productId}
                                              form={form}/>
                    </div>

                    <div className="flex flex-row justify-center mt-5">
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={checked}
                                                               onChange={() => {
                                                                   setChecked(!checked);
                                                               }}/>} style={{color: 'black'}} label="Inkluderad i mål"/>
                        </FormGroup>
                    </div>

                    <button className="dropdown-button">Spara</button>

                </div>
            </form>
        </div>
    )
}
