import React, {useState} from 'react';

import ProductSelectionCard from '../../common/ProductSelection/ProductSelectionCard';
import FlowSelectionCard from '../../common/FlowSelectionCard';
import {Menu, MenuCategory, MenuModel, MenuProduct} from "../../Model/Menu";
import {assertTokenValidity} from "../../common/ReztoToken";
import {FLOW_TYPE} from "../../common/Constants";
import {userReztoStore} from "../../state/rootStore";
import {useForm} from "@mantine/form";
import ReztoSwitch from "../../common/ReztoSwitch";
import {useTranslation} from "react-i18next";

export default function MenuProductForm({
                                            menuModelId, menuId
                                            , menuCategoryId, setCreateDialogOpen, menuProduct
                                        }:
                                            {
                                                menuModelId: string, menuId: string,
                                                menuCategoryId: string,
                                                setCreateDialogOpen: any,
                                                menuProduct?: MenuProduct
                                            }) {

    const {reztoAuth, menuModelStateSlice} = userReztoStore();
    const menuModels = menuModelStateSlice.menuModels;
    const {t} = useTranslation();
    const [deleteMode, setDeleteMode] = useState(false);

    const form = useForm({
        initialValues: {}
    });


    function publish(checked: boolean) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.publishMenuProduct(menuModelId, menuId, menuCategoryId, menuProduct!.id,
                    reztoAuth, !checked);
            });
    }

    function deleteMenuProduct() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                menuModelStateSlice.deleteMenuProduct(menuModelId, menuId, menuCategoryId, menuProduct!.id, reztoAuth);
            });
        setCreateDialogOpen(false);
    }


    function onSub(data: any) {
        if (menuProduct) {
            let menuModelToUpdate: any = {...menuModels.find((menuModel: MenuModel) => menuModel.id === menuModelId)};
            let menuProductToUpdate = menuModelToUpdate.menus
                .find((menu: Menu) => menu.id === menuId).menuCategories
                .find((menuCategory: MenuCategory) => menuCategory.id === menuCategoryId).menuProducts
                .find((mp: MenuProduct) => mp.id === menuProduct.id)

            menuProductToUpdate.includedInMeal = data.checked;
            menuProductToUpdate.productId = data.product.id;

            menuProductToUpdate.productFlowId = data[FLOW_TYPE["Produktflöde - Skroll"]]?.id || "";

            menuProductToUpdate.upSellFlowId = data[FLOW_TYPE['Upsellflöde - Min Order']]?.id || "";


            if (menuProductToUpdate.productId) {
                assertTokenValidity(reztoAuth)
                    .then((_) => {
                        menuModelStateSlice.editMenuModel(menuModelToUpdate, reztoAuth);
                    });
            }


        } else {
            if (data.product) {
                assertTokenValidity(reztoAuth)
                    .then((_) => {
                        menuModelStateSlice.createMenuProduct(menuModelId,
                            menuId,
                            menuCategoryId,
                            {
                                productId: data.product?.id,
                                productFlowId: data[FLOW_TYPE["Produktflöde - Skroll"]]?.id || "",
                                upSellFlowId: data[FLOW_TYPE['Upsellflöde - Min Order']]?.id || "",
                                displayOrder: 0
                            },
                            reztoAuth);
                    });
            }
        }


        setCreateDialogOpen(false);
    }

    return (
        <div className="pt-5 pl-5 pr-5 h-full flex flex-col justify-between">
            {!deleteMode &&
                <form onSubmit={form.onSubmit((values) => onSub(values))}>
                    <div className="flex flex-row gap-2 justify-between h-full ">
                        <ProductSelectionCard
                            prevProductId={menuProduct?.productId}
                            form={form}
                            withName={false}/>
                        <FlowSelectionCard
                            flowType={FLOW_TYPE['Produktflöde - Skroll']}
                            prevFlowId={menuProduct?.productFlowId}
                            form={form}/>
                        <FlowSelectionCard
                            flowType={FLOW_TYPE['Upsellflöde - Min Order']}
                            prevFlowId={menuProduct?.upSellFlowId}
                            form={form}/>
                    </div>

                    <div className="flex flex-col">
                        <div className="flex flex-row justify-around pt-5 pb-5">
                            {menuProduct &&
                                <>
                                    <h1 className="text-normal">Publicerad</h1>
                                    <ReztoSwitch defaultChecked={menuProduct.published} callback={publish}/>
                                </>
                            }
                            {menuProduct &&

                                <button onClick={() => {
                                    setDeleteMode(true)
                                }} className="dropdown-button">{t("DELETE")}</button>

                            }
                            <button type="button"
                                    onClick={() => setCreateDialogOpen(false)}
                                    className="dropdown-button">Avbryt
                            </button>
                            <button className="dropdown-button">Spara</button>
                        </div>
                    </div>

                </form>
            }

            {deleteMode &&
                <div className="flex flex-col justify-center items-center h-full">
                    <h1 className="text-normal bold pb-5">Vill du verkligen radera menyprodukten?</h1>
                    <button onClick={() => {
                        deleteMenuProduct();
                    }} className="dropdown-button mb-5 w-40">Ja
                    </button>
                    <button onClick={() => {
                        setDeleteMode(false)
                    }} className="dropdown-button">Avbryt
                    </button>


                </div>

            }


        </div>
    )
}
