import {StoreSlice} from "./rootStore";

interface selectedRestaurant{
    restaurantId: string;
    restaurantChainId: string;
}
export interface SelectedRestaurantState {
    selectedRestaurant: selectedRestaurant;
    setSelectedRestaurant: (restaurantId: string, restaurantChainId: string ) => void;
}

export const createSelectedRestaurantSlice: StoreSlice<SelectedRestaurantState> = (set, get) => ({
    selectedRestaurant: {restaurantId: "undefined", restaurantChainId: "undefined"},
    setSelectedRestaurant: (restaurantId, restaurantChainId) =>
    {
        set((prev) => ({selectedRestaurant:{ restaurantId: restaurantId, restaurantChainId: restaurantChainId }}))
    },
});
